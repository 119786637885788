var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"border-bottom mb-3 pb-2"},[_c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{ref:"title",staticClass:"form-group"},[_c('label',{attrs:{"for":"titleInput"}},[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"name":"titleInput","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"clearable":false,"options":_vm.titleOptions,"label":"value","reduce":function (item) { return item.value; },"placeholder":"Select"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"firstName",staticClass:"form-group"},[_c('label',{attrs:{"for":"firstNameInput"}},[_vm._v("First name")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"firstName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"firstNameInput"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"lastName",staticClass:"form-group"},[_c('label',{attrs:{"for":"lastNameInput"}},[_vm._v("Last name")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"","name":"lastNameInput"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('button',{staticClass:"btn btn-dark",attrs:{"type":"submit"}},[_vm._v("Update personal details")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }