<template>
  <ValidationObserver ref="formNewPass">
    <b-form @submit.prevent="submitNewPassword" class="mb-3">

      <div class="form-group" ref="currentPassword">
        <label for="currentPassword">Enter current password</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required" name="currentPassword">
          <input v-model="currentPassword" type="password" autocomplete="current-password" placeholder="" name="password" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="password">
        <label for="password">Create new password</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required|max:100" name="password">
          <input v-model="password" type="password" autocomplete="new-password" placeholder="" name="password" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="card">
        <div class="card-body">
          <p class="mb-2">Your password must:</p>
          <ul class="list-requirements">
            <li v-bind:class="{'done': passwordValidation.charLength === true }">be a minimum of 8 characters</li>
            <li v-bind:class="{'done': passwordValidation.uppercase === true }">contain uppercase characters</li>
            <li v-bind:class="{'done': passwordValidation.lowercase === true }">contain lowercase characters</li>
            <li v-bind:class="{'done': passwordValidation.digit === true }">contain at least one digit</li>
            <li v-bind:class="{'done': passwordValidation.special === true }">contain at least one special character</li>
          </ul>
        </div>
      </div>

      <div class="form-group mt-3" ref="password">
        <label for="email">Confirm new password</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required|password:@password" name="passwordConfirm">
          <input v-model="passwordConfirm" type="password" autocomplete="new-password" placeholder="" name="passwordConfirm" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <b-alert variant="danger"
        dismissible
        :show="passwordValidation.errorDisplay"
        @dismissed="passwordValidation.errorDisplay=false">
        <p class="mb-0">Your password doesn't meet all requirements listed above.</p>
      </b-alert>

      <b-alert variant="danger"
        dismissible
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false">
        {{serverError}}
      </b-alert>
      <button type="submit" class="btn btn-dark" >Reset password</button>

    </b-form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'AccountSettingsPasswordDetails',
  props: ['user'],
  data () {
    return {
      currentPassword: '',
      password: '',
      passwordConfirm: '',
      passwordValidation: {
        charLength: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        special: false,
        errorDisplay: false,
        valid: false
      },
      serverError: "",
      showDismissibleAlert: false,
    }
  },
  created: function () {
  },
  methods: {
    submitNewPassword () {
      this.showDismissibleAlert = false
      this.$refs.formNewPass.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formNewPass.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formNewPass.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          if (this.passwordValidation.uppercase && this.passwordValidation.lowercase && this.passwordValidation.digit && this.passwordValidation.special && this.passwordValidation.charLength) {
            this.passwordValidation.errorDisplay = false
            this.$store.state.loadingProgress = true

            this.$http({
              method: 'post', 
              url: this.$api.AccountUpdatePassword,
              headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
              data: {
                currentPassword: this.currentPassword,
                newPassword: this.password,
                confirmPassword: this.password,
              }
            })
            .then((response) => {
              console.log(response)
              if (response.status === 200) {
                this.alertMsgShow('Password changed successfully', true);
              } else {
                this.showError('Something went wrong. Please re-enter the passwords and try again.')
              } 
            })
            .catch((error) => {
              console.error('Error', error.response)
              var errData = error.response.data
              if (errData !== undefined && errData.errors !== undefined && errData.errors.NewPassword !== undefined) {
                this.showError(errData.errors.NewPassword[0])
              } else {
                this.showError(error.response.data)
              }
            })
            .then(() =>  {
              this.$store.state.loadingProgress = false
            })
          } else {
            this.passwordValidation.errorDisplay = true
          }
        }
      });
    },

    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    }
  },
  watch: {
    password () {
      this.passwordValidation = this.passwordValidate(this.password)
    }
  } 
}
</script>
