<template>
<div>
  <b-modal id="modal-verify-number" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Verify your phone number
    </template>
      <div class="d-block">
        <p class="lead">Please complete this step immediately</p>
        <p class="lead">An SMS message containing a verification code has been sent to your verification phone number:</p> 
        <h4 class="h-number text-center mb-3">{{phone.number}}</h4>
        <p>Please enter the code below:</p>
        <ValidationObserver ref="formVerification">
          <b-form @submit.prevent="submitVerifyPhone" class="mb-3">
            <div class="form-group" ref="verificationCode">
              <label for="verificationCode">Verification code</label>
              <ValidationProvider v-slot="{classes, errors}" rules="required" name="Verification code">
                <input v-model="verificationCode" type="number" placeholder="" name="verificationCode" class="form-control form-control-no-spinner" v-bind:class="classes">
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <button type="submit" class="btn btn-warning btn-lg btn-block" >Verify and save</button>
          </b-form>
        </ValidationObserver> 
    </div>
  </b-modal>


  <ValidationObserver ref="formEmail" class="border-bottom mb-2 pb-2">
    <b-form @submit.prevent="submitUpdateEmail" class="mb-3">
      <div class="form-group" ref="email">
        <label for="emailInput">Your email</label>
        <div class="d-flex">
          <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address" class="w-100 mr-1">
            <input v-model="email" type="text" placeholder="" name="emailInput" class="form-control" v-bind:class="classes">
            <div class="invalid-feedback">{{ errors[0] }}</div>
          </ValidationProvider>
          <div>
            <button type="submit" class="btn btn-dark">Update</button>
          </div>
        </div>
      </div>
    </b-form>
  </ValidationObserver>

  <ValidationObserver ref="formPhone">
    <b-form @submit.prevent="submitUpdatePhone" class="mb-3">
      <div class="form-group" ref="phone">
        <label>Your mobile number <button v-on:click="$showInfo('VerificationPhoneNumber')" class="btn form-info" type="button" aria-label="Show info">?</button></label>
        <div class="d-flex">
          <PhoneInputField v-model="phone" class="w-100 mr-1" />
          <div>
            <button type="submit" class="btn btn-dark">Update</button>
          </div>
        </div>
      </div>
    </b-form>
  </ValidationObserver>  
</div>
</template>

<script>
import PhoneInputField from '@/components/modules/PhoneInputField.vue'

export default {
  name: 'AccountSettingsLoginDetails',
  components: {
    PhoneInputField
  },
  props: ['emailData', 'phoneNoData'],
  data () {
    return {
      email: '',
      phone: null,
      verificationCode: '',
    }
  },
  created: function () {
    this.email = this.emailData
    this.phone = this.phoneNoData
  },
  methods: {
    submitUpdateEmail () {
      this.$refs.formEmail.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formEmail.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formEmail.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AccountUpdateEmail,
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
            data: {
              newEmail: this.email,
              confirmEmail: this.email
            }
          })
          .then((response) => {
            console.log(response)
            this.alertMsgShow('Please check your email to confirm this change', true);
          })
          .catch((error) => {
            console.error('Error', error)
            this.alertMsgShow('Something went wrong. Please check your email address and try again.', false);
          })
          .then(()=> this.$store.state.loadingProgress = false)
        }
      });
    },

    submitUpdatePhone: function () {
      this.$refs.formPhone.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formPhone.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formPhone.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          // Validate phone number
          if (this.phone?.valid !== true) {
            this.serverError = "Phone number is not valid";
            this.showDismissibleAlert = true;
          } else {
            this.$store.state.loadingProgress = true
            this.$http({
              method: 'post', 
              url: this.$api.AccountUpdatePhoneNumber,
              params: {
                phoneNo: this.phone.number
              },
              headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
            })
              .then((response) => {
                console.log(response)
                this.$bvModal.show('modal-verify-number')
              })
              .catch((error) => {
                console.error('Error', error.response.data)
                if (error.response.data !== undefined) {
                  this.alertMsgShow(error.response.data, false);
                } else {
                  this.alertMsgShow('Something went wrong', false);
                }
              })
              .then(() => this.$store.state.loadingProgress = false)
          }
        }
      });
    },

    submitVerifyPhone () {
      this.$refs.formVerification.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formVerification.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formVerification.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AccountUpdatePhoneNumberConfirm,
            params: {
              phoneNo: this.phone.number,
              code: this.verificationCode
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              if (response.data.success === true) {
                this.alertMsgShow('Your verification mobile number has been updated', true);
                this.$bvModal.hide('modal-verify-number')
              } else {
                this.alertMsgShow('Incorrect code. Please check your text message and try again.', false);
              }
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Incorrect code. Please try again.')
            })
            .then(() => this.$store.state.loadingProgress = false)
        }
      })
    },

  }
}
</script>
