var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formNewPass"},[_c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitNewPassword.apply(null, arguments)}}},[_c('div',{ref:"currentPassword",staticClass:"form-group"},[_c('label',{attrs:{"for":"currentPassword"}},[_vm._v("Enter current password")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"currentPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentPassword),expression:"currentPassword"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"current-password","placeholder":"","name":"password"},domProps:{"value":(_vm.currentPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.currentPassword=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{ref:"password",staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Create new password")]),_c('ValidationProvider',{attrs:{"rules":"required|max:100","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"new-password","placeholder":"","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"mb-2"},[_vm._v("Your password must:")]),_c('ul',{staticClass:"list-requirements"},[_c('li',{class:{'done': _vm.passwordValidation.charLength === true }},[_vm._v("be a minimum of 8 characters")]),_c('li',{class:{'done': _vm.passwordValidation.uppercase === true }},[_vm._v("contain uppercase characters")]),_c('li',{class:{'done': _vm.passwordValidation.lowercase === true }},[_vm._v("contain lowercase characters")]),_c('li',{class:{'done': _vm.passwordValidation.digit === true }},[_vm._v("contain at least one digit")]),_c('li',{class:{'done': _vm.passwordValidation.special === true }},[_vm._v("contain at least one special character")])])])]),_c('div',{ref:"password",staticClass:"form-group mt-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Confirm new password")]),_c('ValidationProvider',{attrs:{"rules":"required|password:@password","name":"passwordConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"new-password","placeholder":"","name":"passwordConfirm"},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirm=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.passwordValidation.errorDisplay},on:{"dismissed":function($event){_vm.passwordValidation.errorDisplay=false}}},[_c('p',{staticClass:"mb-0"},[_vm._v("Your password doesn't meet all requirements listed above.")])]),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","show":_vm.showDismissibleAlert},on:{"dismissed":function($event){_vm.showDismissibleAlert=false}}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]),_c('button',{staticClass:"btn btn-dark",attrs:{"type":"submit"}},[_vm._v("Reset password")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }