<template>
  <ValidationObserver ref="form" class="border-bottom mb-3 pb-2">
    <b-form @submit.prevent="submit" class="mb-3">
      <div class="form-group" ref="title">
        <label for="titleInput">Title</label>
        <ValidationProvider name="titleInput" rules='required' v-slot="{errors}">
            <v-select
              :clearable="false"
              :options="titleOptions"
              label="value"
              v-model="form.title"
              :reduce="item => item.value"
              placeholder="Select"
              class="form-control"
              :class="{'is-invalid': !!errors.length}"
            ></v-select>
            <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="firstName">
        <label for="firstNameInput">First name</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required" name="firstName">
          <input v-model="form.firstName" type="text" placeholder="" name="firstNameInput" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>

      <div class="form-group" ref="lastName">
        <label for="lastNameInput">Last name</label>
        <ValidationProvider v-slot="{classes, errors}" rules="required" name="lastName">
          <input v-model="form.lastName" type="text" placeholder="" name="lastNameInput" class="form-control" v-bind:class="classes">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <button type="submit" class="btn btn-dark">Update personal details</button>
    </b-form>
  </ValidationObserver> 
</template>

<script>
import accountDetails from '@/mixins/accountDetails'
export default {
  name: 'AccountSettingsPersonalDetails',
  props: ['user'],
  mixins: [accountDetails],
  data() {
    return {
      form: {},
      titleOptions: this.$uiValues.titleOptions,
    }
  },
  created() {
    this.form = {
      ...this.user
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.showDismissibleAlert = false
          console.log('Form validated ready to submit', this.form)
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AccountPersonalDetailsUpdate,
            params: {
              ...this.form
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              this.alertMsgShow('Profile updated successfully', true)
              this.accountPersonalDetailsGet()
            })
            .catch((error) => {
              console.error('Error', error)
              this.alertMsgShow('Something went wrong. Please try again.', false)
            })
            .then(() => this.$store.state.loadingProgress = false)
        }
      });
    }
  }
}
</script>
