<template>
<div>
  <b-modal id="modal-delete-account" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Are you sure?
    </template>
    <div class="d-block">
      <p>Are you sure you want to delete your account?</p>
      <p>This action is irreversible.</p>
      <label for="verificationCode">Type in "DELETE" to activate the delete button</label>
      <input :value="deleteBlocker.toUpperCase()" @input="deleteBlocker = $event.target.value.toUpperCase()" type="text" placeholder="" class="form-control">
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-delete-account')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1" v-on:click="deleteAccount()" :disabled="deleteBlocker !== 'DELETE'">Yes, delete</button>
      </div>
    </div>
  </b-modal>



  <div class="container-fluid wrapper-small mt-5 hero-standard">
    <div class="row mb-3">
      <div class="col-12">
        <b-link class="btn btn-link btn-sm pl-0" to="/dashboard"><span class="icon icon-th-large mr-2 pl-0"></span>Dashboard</b-link>
        <h2 class="anim-load1 mt-2">Account settings</h2>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-small">
    <div class="row mb-5 mt-5">
      <div class="col-12">

        <div v-if="$store.state.user.roles.includes('Therapist') && personalDetails !== null">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-1>Personal details <span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-1" class="mt-2 accordion-content">
            <AccountSettingsPersonalDetails :user="personalDetails" />
          </b-collapse>
          <hr>
        </div>

        <div v-if="email !== null && phoneNo !== null">
          <h2 class="h3 accordion-title" v-b-toggle.collapse-2>Login details <span class="icon-up-open"></span></h2>
          <b-collapse id="collapse-2" class="mt-2 accordion-content">
            <AccountSettingsLoginDetails :emailData="email" :phoneNoData="phoneNo" />
          </b-collapse>
          <hr>
        </div>

 
        <h2 class="h3 accordion-title" v-b-toggle.collapse-3>Password reset <span class="icon-up-open"></span></h2>
        <b-collapse id="collapse-3" class="mt-2 accordion-content">
          <AccountSettingsPasswordDetails :user="userData" />
        </b-collapse>
        <hr>

        <h4 class="mb-3 mt-5">Delete your account</h4>
        <p>By deleting your account, you'll delete all your personal data from the TAYC-R application.</p>
        <b-link class="btn btn-danger btn-sm" v-on:click="deleteAccountCheck()">Delete account</b-link>

      </div>
    </div>
  </div>

  <Footer />
</div>

</template>

<script>
import AccountSettingsPersonalDetails from '@/components/modules/AccountSettingsPersonalDetails.vue'
import AccountSettingsLoginDetails from '@/components/modules/AccountSettingsLoginDetails.vue'
import AccountSettingsPasswordDetails from '@/components/modules/AccountSettingsPasswordDetails.vue'
import accountDetails from '@/mixins/accountDetails'

export default {
  name: 'AccountSettings',
  components: {
    AccountSettingsPersonalDetails, AccountSettingsLoginDetails, AccountSettingsPasswordDetails
  },
  mixins: [accountDetails],
  data () {
    return {
      deleteBlocker: '',
      personalDetails: null,
      email: null,
      phoneNo: null
    }
  },
  computed: {
  },
  async created () {
    this.$store.state.appSection = 'appUser'
    await this.accountPersonalDetailsGet()
    this.attachPersonalDetails()
    await this.attachLoginDetails()
    this.$store.state.loadingProgress = false
  },
  beforeDestroy() {
  },
  methods: {

    async attachLoginDetails() {
      return this.$http({
        method: 'get', 
        url: this.$api.AccountPhoneNoGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response.data)
          this.phoneNo = response.data
          this.email = this.userData.email
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false)
        })
    },

    attachPersonalDetails() {
      this.personalDetails = {
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        title: this.userData.title,
        contactNo: this.userData.contactNo
      }
    },

    deleteAccountCheck() {
      this.deleteBlocker = ''
      this.$bvModal.show('modal-delete-account')
    },

    deleteAccount: function () {
      this.$store.state.loadingProgress = true
      this.$http({
        method: 'post', 
        url: this.$api.AccountDelete,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt }
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow('Your account has been deleted. Thank you for using TAYC-R', true);
          this.signOut()
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong', false);
        })
        .then(() => {
          this.$bvModal.hide('modal-delete-account')
          this.$store.state.loadingProgress = false
        })
    },
  }
}
</script>
